import React from "react";
import { motion } from "framer-motion";
import { Button, Modal, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import the Close icon

const serviceData = [
  {
    id: 1,
    imgSrc: "images/GST_audit.png",
    title: "GST Audit",
    description:
      "We provide GST Audit, Refund, Assessment, LUT filing, and Training services to ensure compliance and ease GST management.",
    delay: 0.2,
  },
  {
    id: 2,
    imgSrc: "images/Internal_audit.png",
    title: "Internal Audit",
    description:
      "We offer Internal Control assessments, Department-wise audits, and Statutory Payment checks to enhance operational efficiency and ensure compliance.",
    delay: 0.4,
  },
  {
    id: 3,
    imgSrc: "images/Cost_audit.png",
    title: "Cost Audit",
    description:
      "We assist with Maintenance of Costing Records, Cost Audits, Make or Buy decisions, Automation, and provide Cost Saving ideas.",
    delay: 0.6,
  },
  {
    id: 4,
    imgSrc: "images/Cost_audit.png",
    title: "Income Tax",
    description:
      "We assist with Maintenance of Costing Records, Cost Audits, Make or Buy decisions, Automation, and provide Cost Saving ideas.",
    delay: 0.8,
  },
  {
    id: 5,
    imgSrc: "images/Cost_audit.png",
    title: "Special Audit",
    description:
      "We assist with Maintenance of Costing Records, Cost Audits, Make or Buy decisions, Automation, and provide Cost Saving ideas.",
    delay: 1.0,
  },
];
const SlideLeft = (delay) => {
  return {
    initial: {
      opacity: 0,
      x: 50,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};

const Services = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <section className="service_section layout_padding">
        <div className="service_container">
          <div className="container">
            <div className="heading_container heading_center">
              <h2>
                Our <span>Services</span>
              </h2>
              <p>
                We provide a comprehensive range of services tailored to
                meet the diverse needs of our clients, ensuring quality,
                precision, and value in every solution we deliver.
              </p>
            </div>
            <div className="row">
              {serviceData.map((service, index) => (
                <motion.div
                  key={service.id}
                  variants={SlideLeft(service.delay)}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true }}
                  className={`col-md-4 ${
                    index === 3 ? "offset-md-2" : ""
                  }`}
                >
                  <div
                  // className={`col-md-4 ${
                  //   index === 3 ? "offset-md-2" : ""
                  // }`}
                  // key={index}
                  >
                    <div className="box" style={{ minHeight: "300px" }}>
                      <div className="img-box">
                        <img src={service.imgSrc} alt={service.title} />
                      </div>
                      <div className="detail-box">
                        <h5>{service.title}</h5>
                        <p>{service.description}</p>
                        <a href=""> Read More </a>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
            <div className="btn-box">
              {/* <a href=""> View All </a> */}
              <Button
                variant="contained"
                style={{
                  background: "#00bbf0",
                  color: "black",
                  fontWeight: "bold",
                }}
                onClick={handleOpen}
              >
                View All
              </Button>
            </div>
          </div>
        </div>
      </section>
      <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box
    sx={{
      position: 'fixed',
      top: '10%', // Space from the top
      bottom: '10%', // Space from the bottom
      left: '50%',
      transform: 'translateX(-50%)',
      width: { xs: '95%', sm: '90%', md: '80%' }, // Responsive width
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    }}
  >
    <Button
      onClick={handleClose}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        minWidth: 0,
        padding: 1,
      }}
      aria-label="close"
    >
      <CloseIcon />
    </Button>
    <Typography
      id="modal-modal-title"
      variant="h6"
      component="h2"
      sx={{
        fontWeight: 'bold',
        color: 'primary.main', // Primary color for title
        textAlign: 'center',
      }}
    >
      Our Other Services
    </Typography>
    <Typography
      id="modal-modal-description"
      sx={{
        mt: 2,
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, // Two columns on larger screens
        gap: 3,
        listStyle: 'none',
        padding: 0,
      }}
    >
      <ul style={{ padding: 0, marginLeft: "10px", listStyleType: 'disc' }}>
        {[
          'New Application of PAN & TAN',
          'Correction of PAN & TAN',
          'Quarterly filing of e-TDS and e-TCS',
          'Filing of TDS returns on quarterly basis • e-TDS and e-TCS',
          'Traces • TDS/TCS',
          'Linking of PAN with AADHAR',
          'Filing of Income Tax return for Individuals, Firms, Corporates',
          'Filing of GSTR 1, 3B Returns on monthly basis, 2A reconciliation and all matters related to GST',
          'Virtual CFO Services',
          'Internal Audit',
          'MSME Registration',
          'Secretarial Services & ROC matters',
          'Power to SME',
        ].map((item, index) => (
          <li key={index} style={{ marginBottom: '8px' }}>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
      <ul style={{ padding: 0, marginLeft: "20px", listStyleType: 'disc' }}>
        {[
          
          'Accounting Services',
          'Business Development',
          'ESI',
          'Meeting Hall for Rent',
          'Legal Opinion',
          'Corporate Training',
          'Due Diligence Audit',
          'DSC',
          'BPO Services',
          'FSSAI',
          'Banking Services',
          'Loan Management',
          'Tax Planning',
        ].map((item, index) => (
          <li key={index} style={{ marginBottom: '8px' }}>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </Typography>
  </Box>
</Modal>


    </div>
  );
};

export default Services;
